
// 获取项目以上角色-树的节点
const getProjectTreeURL = `${API_CONFIG.butlerBaseURL}communityNode/getProjectTree`
// 获取房号详情
const getRoomParamByNodeIdURL = `${API_CONFIG.butlerBaseURL}communityNode/getRoomParamByNodeId`
// 获取楼幢信息
const getCommunityRoomBuildURL = `${API_CONFIG.butlerBaseURL}communityRoomBuild/getCommunityRoomBuild`
// 获楼幢->房屋信息
const getBuildingInfoURL = `${API_CONFIG.butlerBaseURL}communityRoomProfile/getCommunityRoomProfile`
// 获取项目角色-楼幢树节点
const getBuildTreeURL = `${API_CONFIG.butlerBaseURL}communityRoomBuild/getBuildTree`
// 根据房号获取合同信息
const getContractInfoByHouseIdURL = `${API_CONFIG.butlerBaseURL}chargeOrganizationContract/getContractInfoByHouseId`
// 作废合同
const updateStatusHanldeURL = `${API_CONFIG.butlerBaseURL}chargeOrganizationContract/obsoleteChargeContractById`

// 更新或新增
const updateBuildingURL = `${API_CONFIG.butlerBaseURL}communityBuilding/saveCommunityBuildingInfo`
// 获取楼宇信息
const getBaseBuildingInfoURL = `${API_CONFIG.butlerBaseURL}communityBuilding/getCommunityBuildingInfo`
// 上传
const uploadImageURL = `${API_CONFIG.uploadURL}?module=user`

const uploadURL = `${API_CONFIG.uploadURL}/fileUploadNoLimit`

export {
  getProjectTreeURL,
  getBuildTreeURL,
  getCommunityRoomBuildURL,
  getRoomParamByNodeIdURL,
  getBuildingInfoURL,
  getContractInfoByHouseIdURL,
  updateStatusHanldeURL,
  updateBuildingURL,
  getBaseBuildingInfoURL,
  uploadImageURL,
  uploadURL
}
