import { mapHelper, generateMapByOpts } from 'common/utils'

// 是否出售
// const canSell = [
//   {
//     text: '全部',
//     value: undefined
//   }, {
//     text: '是',
//     value: 1
//   }, {
//     text: '否',
//     value: 0
//   }
// ]
const isSell = [
  {
    text: '未出售',
    value: 0
  }, {
    text: '已出售',
    value: 1
  }, {
    text: '售卖中',
    value: 2
  }, {
    text: '出售锁定',
    value: 3
  }
]

const {
  map: isSellMap
} = mapHelper.setMap(isSell)

const isLease = [
  {
    text: '未出租',
    value: 0
  }, {
    text: '已出租',
    value: 1
  }, {
    text: '出租中',
    value: 2
  }, {
    text: '出租锁定',
    value: 3
  }
]
// 建筑类型
const architectureType = [
  {
    text: '多层',
    value: 1
  }, {
    text: '小高层',
    value: 2
  }, {
    text: '高层',
    value: 3
  }, {
    text: '排屋',
    value: 4
  }, {
    text: '别墅',
    value: 5
  }, {
    text: '写字楼',
    value: 6
  }, {
    text: '商场',
    value: 7
  }, {
    text: '会所',
    value: 8
  }, {
    text: '酒店',
    value: 9
  }, {
    text: '停车场',
    value: 10
  }, {
    text: '幼儿园',
    value: 11
  }, {
    text: '商业（综合体）',
    value: 12
  }, {
    text: '公共建筑',
    value: 13
  }, {
    text: '标准办公（超甲级）',
    value: 16
  }, {
    text: '标准办公（甲级）',
    value: 17
  }, {
    text: '标准办公（乙级）',
    value: 18
  }, {
    text: '园区办公（联合）',
    value: 19
  }, {
    text: '独栋办公（<1万方）',
    value: 20
  }, {
    text: '住宅（公寓）',
    value: 21
  }, {
    text: '厂房',
    value: 22
  }, {
    text: '其它',
    value: 15
  }
]
// 楼宇类型
const buildingType = [
  {
    text: '公寓',
    value: 1
  },
  {
    text: '住宅',
    value: 7
  }, {
    text: '排屋',
    value: 2
  },
  {
    text: '别墅',
    value: 3
  }, {
    text: '商铺',
    value: 4
  }, {
    text: '办公用房',
    value: 5
  }, {
    text: '经营用房',
    value: 6
  }, {
    text: '保姆房',
    value: 21
  }, {
    text: '储藏室',
    value: 22
  }, {
    text: '自行车库',
    value: 23
  }, {
    text: '车库',
    value: 24
  }, {
    text: '车位',
    value: 25
  }, {
    text: '其它',
    value: 26
  }, {
    text: '地下室',
    value: 27
  }
]
// 楼宇状态
const buildingStatus = [
  {
    text: '空置',
    value: 1
  },
  {
    text: '未领',
    value: 2
  },
  {
    text: '空关',
    value: 3
  },
  {
    text: '装修',
    value: 4
  },
  {
    text: '入住',
    value: 5
  },
  {
    text: '入伙',
    value: 6
  }
]
// 梯户比
const ladderThanFamilyType = [
  {
    text: '1T1',
    value: 1
  }, {
    text: '1T2',
    value: 2
  }, {
    text: '1T3',
    value: 3
  }, {
    text: '1T4',
    value: 4
  }, {
    text: '2T2',
    value: 5
  }, {
    text: '2T3',
    value: 6
  }, {
    text: '2T4',
    value: 7
  }
]
// 装修类型
const decorateType = [
  {
    text: '毛坯',
    value: 1
  }, {
    text: '精装',
    value: 2
  }
]
// 装修类型
const deliverySituationType = [
  {
    text: '已交付',
    value: 1
  }, {
    text: '未交付',
    value: 2
  }
]


const {
  map: isLeasesMap
} = mapHelper.setMap(isLease)

const architectureTypeMap = generateMapByOpts(architectureType)
const ladderThanFamilyTypeMap = generateMapByOpts(ladderThanFamilyType)
const buildingTypeMap = generateMapByOpts(buildingType)
const deliverySituationTypeMap = generateMapByOpts(deliverySituationType)
const buildingStatusMap = generateMapByOpts(buildingStatus)
// 是否出租
// const isLease = [
//   {
//     text: '全部',
//     value: undefined
//   }, {
//     text: '是',
//     value: 1
//   }, {
//     text: '否',
//     value: 0
//   }
// ]

// tree图标映射
// const fileUrl = 'src/views/project/projectHouseConfig/validHouse/img/'
const fileUrl = './img/'
const treeIconMap = {
  roomTreeDiyIcon0: {
    iconOpen: require(`${fileUrl}open_company.png`),
    iconClose: require(`${fileUrl}close_company.png`)
  },
  roomTreeDiyIcon1: {
    iconOpen: require(`${fileUrl}open_community.png`),
    iconClose: require(`${fileUrl}close_community.png`)
  },
  roomTreeDiyIcon2: {
    iconOpen: require(`${fileUrl}open_region.png`),
    iconClose: require(`${fileUrl}close_region.png`)
  },
  roomTreeDiyIcon3: {
    iconOpen: require(`${fileUrl}open_yuan.png`),
    iconClose: require(`${fileUrl}close_yuan.png`)
  },
  roomTreeDiyIcon4: {
    iconOpen: require(`${fileUrl}open_zhuang.png`),
    iconClose: require(`${fileUrl}close_zhuang.png`)
  },
  roomTreeDiyIcon5: {
    iconOpen: require(`${fileUrl}open_unit.png`),
    iconClose: require(`${fileUrl}close_unit.png`)
  }
}
const noContactImg = require(`${fileUrl}nocontact.png`)
export {
  treeIconMap,
  isSellMap,
  isLeasesMap,
  noContactImg,
  architectureType,
  buildingType,
  buildingStatus,
  ladderThanFamilyType,
  decorateType,
  deliverySituationType,
  architectureTypeMap,
  ladderThanFamilyTypeMap,
  buildingTypeMap,
  deliverySituationTypeMap,
  buildingStatusMap,
}
